<div *ngIf="displayTitle" mat-dialog-title>{{ displayTitle }}</div>
<div *ngIf="data?.titleTranslationKey" mat-dialog-title>
  {{ data?.titleTranslationKey || "" | translate }}
</div>
<div mat-dialog-content>
  <ng-content></ng-content>
  <div *ngIf="data?.messageTranslationKey">
    {{ data?.messageTranslationKey || "" | translate }}
  </div>
</div>
<bi-button square variant="plain" size="s" matDialogClose class="close-button">
  <i class="fa fa-times"></i>
</bi-button>
